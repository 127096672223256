import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "../CSS/bookunits.css";
import stores from "../Dealer Mapping OMS.json";
import pricingTable from "../images/Dealer Prices.jpg";
import Modal from "react-modal";

const BookUnitsScreen = () => {
  const [uniqueStates, setUniqueStates] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [filteredCities, setFilteredCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [geoJSONStores, setGeoJSONStores] = useState(null);
  const [selectedStores, setSelectedStores] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedDealer, setSelectedDealer] = useState("");
  const [isFormFill, setIsFormFill] = useState(false);
  const [isUnitsFill, setIsUnitsFill] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [customUnitValue, setCustomUnitValue] = useState("");
  const [step, setStep] = useState("1");
  const [isRegistered, setIsRegistered] = useState(false);
  const naviagte = useNavigate();
  const [isVisible, setIsVisible] = useState(false);

  const targetTime = new Date("2024-09-24T15:30:00");

  useEffect(() => {
    const checkTime = () => {
      const currentTime = new Date();
      if (currentTime >= targetTime) {
        setIsVisible(true);
      }
    };
    checkTime();

    const intervalId = setInterval(checkTime, 1000);

    return () => clearInterval(intervalId);
  }, [targetTime]);

  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
    setSelectedCity("");
  };

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
  };

  const handleDealerChange = (event) => {
    setSelectedDealer(event.target.value);
  };

  useEffect(() => {
    if (stores && stores.length > 0) {
      const geoJSON = stores;
      setGeoJSONStores(geoJSON);
      const states = [...new Set(geoJSON.map((item) => item.state))].sort();
      setUniqueStates(states);
    }
  }, [stores]);

  useEffect(() => {
    if (geoJSONStores) {
      const cities = [
        ...new Set(
          geoJSONStores
            .filter((item) => item.state === selectedState)
            .map((item) => item.city)
        ),
      ].sort();
      setFilteredCities(cities);
    }
  }, [selectedState, geoJSONStores]);

  useEffect(() => {
    const handleSearch = () => {
      let selectedStoresData;

      if (!(selectedCity === "" && selectedState === "")) {
        selectedStoresData = geoJSONStores.filter((item) => {
          let { name, city, state } = item;

          return state === selectedState && city === selectedCity;
        });
      } else {
        selectedStoresData = selectedStores;
      }

      setSelectedStores(selectedStoresData);
    };

    handleSearch();
  }, [selectedCity, selectedState]);

  const formSubmit = async (e) => {
    e.preventDefault();
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        `/api/form/units/dealer`,
        { selectedDealer },
        config
      );

      if (data) {
        setStep("2");
        setIsFormFill(true);
      }
    } catch (error) {
      naviagte("/prebook-confirmed");
    }
  };

  const unitsSubmit = async (e) => {
    e.preventDefault();
    if (selectedOption || customUnitValue) {
      const selectedUnits = selectedOption ? selectedOption : customUnitValue;
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const { data } = await axios.post(
          "/api/form/units",
          {
            selectedState,
            selectedCity,
            selectedDealer,
            phoneNumber,
            selectedUnits,
          },
          config
        );

        if (data) {
          setIsFormFill(false);
          setIsUnitsFill(true);
        }
      } catch (error) {
        console.error("Form submission error:", error);
      }
    } else {
      alert("Please select the units or fill the input box.");
      setCustomUnitValue("");
      setSelectedOption("");
    }
  };

  const [units, setUnits] = useState(500);
  const constantTime = new Date("2024-09-24T15:30:00");

  useEffect(() => {
    const updateUnits = () => {
      const currentTime = new Date();
      const timeDiffInSeconds = Math.floor((currentTime - constantTime) / 1000);
      if (timeDiffInSeconds > 0) {
        setUnits((prevUnits) => {
          if (timeDiffInSeconds <= 1800) {
            // For the first 30 minutes (1800 seconds), deduct 4 units every 15 seconds
            const unitsToDeduct = Math.floor(timeDiffInSeconds / 15) * 4;
            return Math.max(500 - unitsToDeduct, 0);
          } else {
            // After 30 minutes (after 1800 seconds), deduct 2 units every 4 minutes (240 seconds)
            const unitsToDeduct =
              Math.floor((timeDiffInSeconds - 1800) / 240) * 2;
            return Math.max(20 - unitsToDeduct, 0);
          }
        });
      }
    };

    const intervalId = setInterval(updateUnits, 500); // Update every 500ms

    return () => clearInterval(intervalId);
  }, [constantTime]);
  const [randomNumber, setRandomNumber] = useState(0);

  const getUserNumber = () => {
    return Math.floor(Math.random() * (15 - 10 + 1)) + 10;
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRandomNumber(getUserNumber());
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div className="book-units-screen poppins-regular">
      {isVisible ? (
        <>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            className="col col-md-4"
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                display: "flex",
                zIndex: "100",
                alignItems: "center",
                justifyContent: "center",
              },
              content: {
                position: "static",
                margin: "auto",
                border: "none",
                padding: "0px",
                outline: "none",
                overflow: "hidden",
              },
            }}
            contentLabel="Video Modal"
          >
            <div
              className="d-flex align-items-center"
              style={{ flexDirection: "column" }}
            >
              <div className="p-1 p-lg-3">
                <img src={pricingTable} className="img-fluid"></img>
              </div>
              <button
                className="btn btn-danger px-4 my-2 px-lg-5"
                onClick={closeModal}
                style={{ fontSize: "0.8em" }}
              >
                Close
              </button>
            </div>
          </Modal>
          {!isFormFill && !isUnitsFill && (
            <>
              <div className="d-lg-none" style={{ height: "100vh" }}>
                <div className="details-component text-center p-lg-5 p-4 pb-0">
                  <h1
                    className="my-0"
                    style={{ fontFamily: "SpeedBeast", fontSize: "30px" }}
                  >
                    e motorad
                  </h1>
                  <h3 style={{ fontSize: "18px" }}>Booking Portal</h3>
                  {units ? (
                    <>
                      <p
                        className="text-start mt-4 mb-2 poppins-light"
                        style={{ fontSize: "11.8px", color: "#606060" }}
                      >
                        Step {step}/2
                      </p>
                      <div className="d-flex w-100 mb-4" style={{ gap: "1em" }}>
                        <hr
                          className="m-0 w-50"
                          style={{
                            borderTop: `5px solid ${
                              step === "1" || step === "2"
                                ? "#004dac"
                                : "#D9D9D9"
                            }`,
                            borderRadius: "25px",
                            opacity: 1,
                          }}
                        ></hr>
                        <hr
                          className="m-0 w-50"
                          style={{
                            borderTop: `5px solid ${
                              step === "2" ? "#004dac" : "#D9D9D9"
                            }`,
                            borderRadius: "25px",
                            opacity: 1,
                          }}
                        ></hr>
                      </div>
                      <div className="d-flex" style={{}}></div>
                      <h5
                        className="mb-2 my-lg-5 text-start"
                        style={{ color: "#606060", fontSize: "17px" }}
                      >
                        Select your Dealership
                      </h5>
                      <div
                        className="mb-3 my-lg-5 py-2 py-lg-5"
                        style={{ color: "#606060" }}
                      >
                        <form className="ps-lg-0" onSubmit={formSubmit}>
                          <div className="row text-start justify-content-center p-0">
                            <div className="d-flex mb-2" style={{ gap: "1em" }}>
                              <div className="w-50">
                                <h6
                                  className=" mt-2 mb-2 poppins-light"
                                  style={{ fontSize: "11.8px" }}
                                >
                                  State*
                                </h6>
                                <select
                                  id="state-dropdown"
                                  required
                                  className="form-select poppins-light"
                                  aria-label="Select a State"
                                  value={selectedState}
                                  onChange={handleStateChange}
                                  style={{ fontSize: "14px", color: "#606060" }}
                                >
                                  <option value="" disabled>
                                    Select state
                                  </option>
                                  {uniqueStates.map((state, index) => (
                                    <option key={index} value={state}>
                                      {state}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="w-50">
                                <h6
                                  className="mt-2 mb-2 poppins-light"
                                  style={{ fontSize: "11.8px" }}
                                >
                                  City*
                                </h6>
                                <select
                                  id="city-dropdown"
                                  required
                                  className="form-select poppins-light"
                                  aria-label="Select a City"
                                  value={selectedCity}
                                  onChange={handleCityChange}
                                  style={{ fontSize: "14px", color: "#606060" }}
                                >
                                  <option value="" disabled>
                                    Select city
                                  </option>
                                  {filteredCities.map((city, index) => (
                                    <option key={index} value={city}>
                                      {city}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <div className="mb-2">
                              <h6
                                className="mt-2 mb-2 poppins-light"
                                style={{ fontSize: "11.8px" }}
                              >
                                Dealer*
                              </h6>
                              <select
                                id="city-dropdown"
                                className="form-select poppins-light"
                                required
                                aria-label="Select a Dealer"
                                value={selectedDealer}
                                onChange={handleDealerChange}
                                style={{ fontSize: "14px", color: "#606060" }}
                              >
                                <option value="" disabled>
                                  Select dealer
                                </option>
                                {selectedStores.map((store, index) => (
                                  <option key={index} value={store.name}>
                                    {store.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="mt-0 mt-lg-4">
                              <h6
                                className="mt-2 mb-2 poppins-light"
                                style={{ fontSize: "11.8px" }}
                              >
                                Phone Number*
                              </h6>
                              <input
                                type="tel"
                                name="phone number"
                                className="form-control poppins-light"
                                value={phoneNumber}
                                placeholder="Enter phone number"
                                required
                                style={{ fontSize: "14px", color: "#606060" }}
                                onChange={(e) => {
                                  const mobileNumber = e.target.value.slice(
                                    0,
                                    12
                                  );
                                  setPhoneNumber(mobileNumber);

                                  if (/^[6-9]\d{9}$/.test(mobileNumber)) {
                                    e.target.setCustomValidity("");
                                  } else {
                                    e.target.setCustomValidity(
                                      "Enter a valid phone number (10 digits, starting with 6, 7, 8, or 9)"
                                    );
                                  }
                                }}
                                onInvalid={(e) =>
                                  e.target.setCustomValidity(
                                    "Enter a valid phone number (10 digits, starting with 6, 7, 8, or 9)"
                                  )
                                }
                                autoComplete="new-password"
                              />
                            </div>
                          </div>
                          <div className="d-flex justify-content-center mt-2 w-100">
                            <div className="col-md-3 mt-3 w-100">
                              <button
                                className="btn px-5 text-em background-em text-light w-100 poppins-medium book-dealer-submit"
                                type="submit"
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </>
                  ) : (
                    <>
                      {/* <img
                    className="img-fluid"
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/WhatsApp+Image+2024-09-21+at+16.24.31.jpeg"
                  ></img> */}
                      <h1
                        className="mt-4 poppins-bold-italic"
                        style={{ fontSize: "70px", color: "#004dac" }}
                      >
                        Oops
                      </h1>
                      <h3 style={{ fontSize: "38px" }} className="mb-5">
                        <em>We are sold out!</em>
                      </h3>
                    </>
                  )}
                </div>
                <div className="w-100">
                  <img
                    className="img-fluid"
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/bookingportal_mobile.png"
                  ></img>
                </div>
              </div>
              <div
                className="d-lg-block d-none"
                style={{ backgroundColor: "#F4FCFF", height: "100vh" }}
              >
                <div className="background-cycle position-relative">
                  <div className="position-absolute  form-book-desktop">
                    <div
                      className="details-component bg-white text-center p-lg-5 p-4 pb-0"
                      style={{ height: "100vh", width: "420px" }}
                    >
                      <h1
                        className="my-0"
                        style={{ fontFamily: "SpeedBeast", fontSize: "54px" }}
                      >
                        e motorad
                      </h1>
                      <h3 style={{ fontSize: "30px" }}>Booking Portal</h3>
                      {units ? (
                        <>
                          <p
                            className="text-start mt-5 poppins-light"
                            style={{ fontSize: "11.8px", color: "#606060" }}
                          >
                            Step {step}/2
                          </p>
                          <div
                            className="d-flex w-100 mb-4"
                            style={{ gap: "1em" }}
                          >
                            <hr
                              className="mb-2 m-0 w-50"
                              style={{
                                borderTop: `5px solid ${
                                  step === "1" || step === "2"
                                    ? "#004dac"
                                    : "#D9D9D9"
                                }`,
                                borderRadius: "25px",
                                opacity: 1,
                              }}
                            ></hr>
                            <hr
                              className="mb-2 m-0 w-50"
                              style={{
                                borderTop: `5px solid ${
                                  step === "2" ? "#004dac" : "#D9D9D9"
                                }`,
                                borderRadius: "25px",
                                opacity: 1,
                              }}
                            ></hr>
                          </div>
                          <div className="d-flex" style={{}}></div>
                          <h5
                            className="mb-2 text-start"
                            style={{ color: "#606060", fontSize: "20px" }}
                          >
                            Select your Dealership
                          </h5>
                          <div
                            className="mb-3 mt-4"
                            style={{ color: "#606060" }}
                          >
                            <form className="ps-lg-0" onSubmit={formSubmit}>
                              <div className="row text-start justify-content-center p-0">
                                <div className="mb-2">
                                  <h6
                                    className=" mt-2 mb-2 poppins-light"
                                    style={{ fontSize: "12.8px" }}
                                  >
                                    State*
                                  </h6>
                                  <select
                                    id="state-dropdown"
                                    required
                                    className="form-select poppins-light"
                                    aria-label="Select a State"
                                    value={selectedState}
                                    onChange={handleStateChange}
                                    style={{
                                      fontSize: "16px",
                                      color: "#606060",
                                    }}
                                  >
                                    <option value="" disabled>
                                      Select state
                                    </option>
                                    {uniqueStates.map((state, index) => (
                                      <option key={index} value={state}>
                                        {state}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div className="mb-2">
                                  <h6
                                    className="mt-2 mb-2 poppins-light"
                                    style={{ fontSize: "12.8px" }}
                                  >
                                    City*
                                  </h6>
                                  <select
                                    id="city-dropdown"
                                    required
                                    className="form-select poppins-light"
                                    aria-label="Select a City"
                                    value={selectedCity}
                                    onChange={handleCityChange}
                                    style={{
                                      fontSize: "16px",
                                      color: "#606060",
                                    }}
                                  >
                                    <option value="" disabled>
                                      Select city
                                    </option>
                                    {filteredCities.map((city, index) => (
                                      <option key={index} value={city}>
                                        {city}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div className="mb-2">
                                  <h6
                                    className="mt-2 mb-2 poppins-light"
                                    style={{ fontSize: "12.8px" }}
                                  >
                                    Dealer*
                                  </h6>
                                  <select
                                    id="city-dropdown"
                                    className="form-select poppins-light"
                                    required
                                    aria-label="Select a Dealer"
                                    value={selectedDealer}
                                    onChange={handleDealerChange}
                                    style={{
                                      fontSize: "16px",
                                      color: "#606060",
                                    }}
                                  >
                                    <option value="" disabled>
                                      Select dealer
                                    </option>
                                    {selectedStores.map((store, index) => (
                                      <option key={index} value={store.name}>
                                        {store.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div className="mt-0">
                                  <h6
                                    className="mt-2 mb-2 poppins-light"
                                    style={{ fontSize: "12.8px" }}
                                  >
                                    Phone Number*
                                  </h6>
                                  <input
                                    type="tel"
                                    name="phone number"
                                    className="form-control poppins-light"
                                    value={phoneNumber}
                                    placeholder="Enter phone number"
                                    required
                                    style={{
                                      fontSize: "16px",
                                      color: "#606060",
                                    }}
                                    onChange={(e) => {
                                      const mobileNumber = e.target.value.slice(
                                        0,
                                        12
                                      );
                                      setPhoneNumber(mobileNumber);

                                      if (/^[6-9]\d{9}$/.test(mobileNumber)) {
                                        e.target.setCustomValidity("");
                                      } else {
                                        e.target.setCustomValidity(
                                          "Enter a valid phone number (10 digits, starting with 6, 7, 8, or 9)"
                                        );
                                      }
                                    }}
                                    onInvalid={(e) =>
                                      e.target.setCustomValidity(
                                        "Enter a valid phone number (10 digits, starting with 6, 7, 8, or 9)"
                                      )
                                    }
                                    autoComplete="new-password"
                                  />
                                </div>
                              </div>
                              <div className="d-flex justify-content-center mt-2 w-100">
                                <div className="col-md-3 mt-3 w-100">
                                  <button
                                    className="btn px-5 w-100 poppins-medium text-decoration-none book-dealer-submit"
                                    type="submit"
                                  >
                                    Submit
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </>
                      ) : (
                        <>
                          {/* <img
                        className="img-fluid mt-5 pt-5"
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/WhatsApp+Image+2024-09-21+at+16.24.31.jpeg"
                      ></img> */}
                          <h1
                            className="mt-5 pt-5 poppins-bold-italic"
                            style={{ fontSize: "70px", color: "#004dac" }}
                          >
                            Oops
                          </h1>
                          <h3 style={{ fontSize: "38px" }} className="mb-5">
                            <em>We are sold out!</em>
                          </h3>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {isFormFill && (
            <>
              <div className="d-lg-none" style={{ height: "100vh" }}>
                <div className="details-component text-center p-lg-5 p-4 pb-0">
                  <div className="text-end">
                    <Icon icon="mdi:eye" width="1em" height="1em" />{" "}
                    <span style={{ fontSize: "0.7em", color: "#606060" }}>
                      {randomNumber} live viewers
                    </span>
                  </div>
                  <p
                    className="text-start my-1 poppins-light"
                    style={{ fontSize: "11.8px", color: "#606060" }}
                  >
                    Step {step}/2
                  </p>
                  <div className="d-flex w-100 mb-3" style={{ gap: "1em" }}>
                    <hr
                      className="m-0 w-50"
                      style={{
                        borderTop: `5px solid ${
                          step === "1" || step === "2" ? "#004dac" : "#D9D9D9"
                        }`,
                        borderRadius: "25px",
                        opacity: 1,
                      }}
                    ></hr>
                    <hr
                      className="m-0 w-50"
                      style={{
                        borderTop: `5px solid ${
                          step === "2" ? "#004dac" : "#D9D9D9"
                        }`,
                        borderRadius: "25px",
                        opacity: 1,
                      }}
                    ></hr>
                  </div>
                  {units ? (
                    <>
                      <h1
                        className="my-0 poppins-bold-italic"
                        style={{ fontSize: "25px", color: "#606060" }}
                      >
                        Only{" "}
                        <span style={{ color: "#004dac", fontSize: "35px" }}>
                          {units}
                        </span>{" "}
                        units left
                      </h1>
                      <h3 style={{ fontSize: "19px" }} className="mt-3">
                        <em>T-Rex Pro Selling Fast</em>
                      </h3>
                      <div className="d-flex" style={{}}></div>
                      <h5
                        className="mb-4 poppins-bold"
                        style={{ color: "#606060", fontSize: "18px" }}
                      >
                        Order Now
                      </h5>
                      <small>Select order quantity</small>
                      <div
                        className="mb-3 my-lg-5 py-2 py-lg-5"
                        style={{ color: "#606060" }}
                      >
                        <form className="ps-lg-0" onSubmit={unitsSubmit}>
                          <div
                            className="d-flex align-items-center"
                            style={{ flexDirection: "column" }}
                          >
                            <div className="d-flex mb-3" style={{ gap: "1em" }}>
                              <div>
                                <input
                                  className="btn-check form__input"
                                  type="radio"
                                  name="options"
                                  id="option1"
                                  onChange={(e) => {
                                    setSelectedOption("2");
                                    setCustomUnitValue("");
                                  }}
                                />
                                <label
                                  className="form__label-book btn btn-light text-dark px-4"
                                  style={{
                                    fontSize: "13px",
                                    backgroundColor: "#EDF5FF",
                                  }}
                                  htmlFor="option1"
                                >
                                  2 Units
                                </label>
                              </div>
                              <div>
                                <input
                                  className="btn-check form__input"
                                  type="radio"
                                  name="options"
                                  id="option2"
                                  onChange={(e) => {
                                    setSelectedOption("5");
                                    setCustomUnitValue("");
                                  }}
                                />
                                <label
                                  className="form__label-book btn btn-light text-dark px-4"
                                  style={{
                                    fontSize: "13px",
                                    backgroundColor: "#EDF5FF",
                                  }}
                                  htmlFor="option2"
                                >
                                  5 Units
                                </label>
                              </div>
                              <div>
                                <input
                                  className="btn-check form__input"
                                  type="radio"
                                  name="options"
                                  id="option3"
                                  onChange={(e) => {
                                    setSelectedOption("7");
                                    setCustomUnitValue("");
                                  }}
                                />
                                <label
                                  className="form__label-book btn btn-light text-dark px-4"
                                  style={{
                                    fontSize: "13px",
                                    backgroundColor: "#EDF5FF",
                                  }}
                                  htmlFor="option3"
                                >
                                  7 Units
                                </label>
                              </div>
                            </div>
                            <div className="d-flex mb-3" style={{ gap: "1em" }}>
                              <div>
                                <input
                                  className="btn-check form__input"
                                  type="radio"
                                  name="options"
                                  id="option4"
                                  onChange={(e) => {
                                    setSelectedOption("10");
                                    setCustomUnitValue("");
                                  }}
                                />
                                <label
                                  className="form__label-book btn btn-light text-dark px-4"
                                  style={{
                                    fontSize: "12.4px",
                                    backgroundColor: "#EDF5FF",
                                  }}
                                  htmlFor="option4"
                                >
                                  10 Units
                                </label>
                              </div>
                              <div>
                                <input
                                  className="btn-check form__input"
                                  type="radio"
                                  name="options"
                                  id="option5"
                                  onChange={(e) => {
                                    setSelectedOption("20");
                                    setCustomUnitValue("");
                                  }}
                                />
                                <label
                                  className="form__label-book btn btn-light text-dark px-4"
                                  style={{
                                    fontSize: "12.4px",
                                    backgroundColor: "#EDF5FF",
                                  }}
                                  htmlFor="option5"
                                >
                                  20 Units
                                </label>
                              </div>
                              <div>
                                <input
                                  className="btn-check form__input"
                                  type="radio"
                                  name="options"
                                  id="option6"
                                  onChange={(e) => {
                                    setSelectedOption("30");
                                    setCustomUnitValue("");
                                  }}
                                />
                                <label
                                  className="form__label-book btn btn-light text-dark px-4"
                                  style={{
                                    fontSize: "12.4px",
                                    backgroundColor: "#EDF5FF",
                                  }}
                                  htmlFor="option6"
                                >
                                  30 Units
                                </label>
                              </div>
                            </div>
                          </div>
                          <h6
                            className="mt-2 poppins-light"
                            style={{ fontSize: "11.8px" }}
                          >
                            Upto Rs. 3000 OFF on Dealer Price<br></br>Limited
                            Period Offer*
                          </h6>
                          <div className="d-flex justify-content-center mt-0 mb-4 w-100">
                            <div className="d-flex justify-content-center w-100">
                              <div
                                className="btn px-3 py-2 text-em background-em text-light w-50 poppins-medium book-dealer-submit"
                                onClick={openModal}
                                style={{ fontSize: "0.9em" }}
                              >
                                Pricing Details
                              </div>
                            </div>
                          </div>
                          <div>
                            <h6
                              className="mt-2 mb-2 poppins-light"
                              style={{ fontSize: "11.8px" }}
                            >
                              Have another number? Enter here!
                            </h6>
                            <input
                              type="number"
                              className="form-control poppins-light"
                              autofocus="autofocus"
                              value={customUnitValue}
                              placeholder="Enter number of units"
                              onChange={(e) => {
                                setCustomUnitValue(e.target.value);
                                setSelectedOption("");
                              }}
                            />
                          </div>
                          <div className="d-flex justify-content-center mt-2 w-100">
                            <div className="col-md-3 mt-3 w-100">
                              <button
                                className="btn px-5 text-em background-em text-light w-100 poppins-medium book-dealer-submit"
                                type="submit"
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </>
                  ) : (
                    <>
                      <h1
                        className="mt-4 poppins-bold-italic"
                        style={{ fontSize: "70px", color: "#004dac" }}
                      >
                        Oops
                      </h1>
                      <h3 style={{ fontSize: "38px" }} className="mb-5">
                        <em>We are sold out!</em>
                      </h3>
                    </>
                  )}
                </div>
                <div className="w-100">
                  {units ? (
                    <img
                      className="img-fluid"
                      style={{ opacity: "0.6" }}
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/bookingportal_mobile.png"
                    ></img>
                  ) : (
                    <img
                      className="img-fluid"
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/bookingportal_thanks.png"
                    ></img>
                  )}
                </div>
              </div>
              <div
                className="d-lg-block d-none"
                style={{
                  backgroundColor: "#F4FCFF",
                  height: "100vh",
                  maxWidth: "100vw",
                  overflow: "hidden",
                }}
              >
                <div className="background-cycle position-relative">
                  <div className="position-absolute form-book-desktop">
                    <div
                      className="details-component bg-white text-center p-lg-5 p-4 pb-0"
                      style={{ height: "100vh", width: "420px" }}
                    >
                      <div className="text-end mt-3">
                        <Icon icon="mdi:eye" width="1em" height="1em" />{" "}
                        <span style={{ fontSize: "0.7em", color: "#606060" }}>
                          {randomNumber} live viewers
                        </span>
                      </div>
                      <p
                        className="text-start my-1 poppins-light"
                        style={{ fontSize: "11.8px", color: "#606060" }}
                      >
                        Step {step}/2
                      </p>
                      <div className="d-flex w-100 mb-4" style={{ gap: "1em" }}>
                        <hr
                          className="m-0 w-50"
                          style={{
                            borderTop: `5px solid ${
                              step === "1" || step === "2"
                                ? "#004dac"
                                : "#D9D9D9"
                            }`,
                            borderRadius: "25px",
                            opacity: 1,
                          }}
                        ></hr>
                        <hr
                          className="m-0 w-50"
                          style={{
                            borderTop: `5px solid ${
                              step === "2" ? "#004dac" : "#D9D9D9"
                            }`,
                            borderRadius: "25px",
                            opacity: 1,
                          }}
                        ></hr>
                      </div>
                      {units ? (
                        <>
                          <h1
                            className="my-0 poppins-bold-italic"
                            style={{ fontSize: "30px", color: "#606060" }}
                          >
                            Only{" "}
                            <span
                              style={{ color: "#004dac", fontSize: "40px" }}
                            >
                              {units}
                            </span>{" "}
                            units left
                          </h1>
                          <h3 style={{ fontSize: "21px" }} className="mt-3">
                            <em>T-Rex Pro Selling Fast</em>
                          </h3>
                          <div className="d-flex" style={{}}></div>
                          <h5
                            className="mb-5 poppins-bold"
                            style={{ color: "#606060", fontSize: "20px" }}
                          >
                            Order Now
                          </h5>
                          <small>Select order quantity</small>
                          <div
                            className="mb-3 py-2"
                            style={{ color: "#606060" }}
                          >
                            <form className="ps-lg-0" onSubmit={unitsSubmit}>
                              <div
                                className="d-flex align-items-center"
                                style={{ flexDirection: "column" }}
                              >
                                <div
                                  className="d-flex mb-3"
                                  style={{ gap: "1em" }}
                                >
                                  <div>
                                    <input
                                      type="radio"
                                      className="btn-check w-100"
                                      name="options"
                                      id="option-1"
                                      autocomplete="off"
                                      onChange={() => {
                                        setSelectedOption("2");
                                        setCustomUnitValue("");
                                      }}
                                    />
                                    <label
                                      className="btn btn-light text-dark px-4"
                                      style={{
                                        fontSize: "12.3px",
                                        backgroundColor: "#EDF5FF",
                                      }}
                                      htmlFor="option-1"
                                    >
                                      2 Units
                                    </label>
                                  </div>
                                  <div>
                                    <input
                                      type="radio"
                                      className="btn-check w-100"
                                      name="options"
                                      id="option-2"
                                      autocomplete="off"
                                      onChange={() => {
                                        setSelectedOption("5");
                                        setCustomUnitValue("");
                                      }}
                                    />
                                    <label
                                      className="btn btn-light text-dark px-4"
                                      htmlFor="option-2"
                                      style={{
                                        fontSize: "12.3px",
                                        backgroundColor: "#EDF5FF",
                                      }}
                                    >
                                      5 Units
                                    </label>
                                  </div>
                                  <div>
                                    <input
                                      type="radio"
                                      className="btn-check w-100"
                                      name="options"
                                      id="option-3"
                                      autocomplete="off"
                                      onChange={() => {
                                        setSelectedOption("7");
                                        setCustomUnitValue("");
                                      }}
                                    />
                                    <label
                                      className="btn btn-light text-dark px-4"
                                      htmlFor="option-3"
                                      style={{
                                        fontSize: "13px",
                                        backgroundColor: "#EDF5FF",
                                      }}
                                    >
                                      7 Units
                                    </label>
                                  </div>
                                </div>
                                <div
                                  className="d-flex mb-3"
                                  style={{ gap: "1em" }}
                                >
                                  <div>
                                    <input
                                      type="radio"
                                      className="btn-check w-100"
                                      name="options"
                                      id="option-4"
                                      autocomplete="off"
                                      onChange={() => {
                                        setSelectedOption("10");
                                        setCustomUnitValue("");
                                      }}
                                    />
                                    <label
                                      className="btn btn-light text-dark px-4"
                                      style={{
                                        fontSize: "12.3px",
                                        backgroundColor: "#EDF5FF",
                                      }}
                                      htmlFor="option-4"
                                    >
                                      10 Units
                                    </label>
                                  </div>
                                  <div>
                                    <input
                                      type="radio"
                                      className="btn-check w-100"
                                      name="options"
                                      id="option-5"
                                      autocomplete="off"
                                      onChange={() => {
                                        setSelectedOption("20");
                                        setCustomUnitValue("");
                                      }}
                                    />
                                    <label
                                      className="btn btn-light text-dark px-4"
                                      htmlFor="option-5"
                                      style={{
                                        fontSize: "12.3px",
                                        backgroundColor: "#EDF5FF",
                                      }}
                                    >
                                      20 Units
                                    </label>
                                  </div>
                                  <div>
                                    <input
                                      type="radio"
                                      className="btn-check w-100"
                                      name="options"
                                      id="option-6"
                                      autocomplete="off"
                                      onChange={() => {
                                        setSelectedOption("30");
                                        setCustomUnitValue("");
                                      }}
                                    />
                                    <label
                                      className="btn btn-light text-dark px-4"
                                      htmlFor="option-6"
                                      style={{
                                        fontSize: "12.3px",
                                        backgroundColor: "#EDF5FF",
                                      }}
                                    >
                                      30 Units
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <h6
                                className="mt-4 poppins-light"
                                style={{ fontSize: "13.8px" }}
                              >
                                Upto Rs. 3000 OFF on Dealer Price<br></br>
                                Limited Period Offer*
                              </h6>
                              <div className="d-flex justify-content-center mt-0 mb-4 w-100">
                                <div className="d-flex justify-content-center w-100">
                                  <div
                                    className="btn px-3 py-2 text-em background-em text-light w-50 poppins-medium book-dealer-submit"
                                    onClick={openModal}
                                  >
                                    Pricing Details
                                  </div>
                                </div>
                              </div>
                              <div>
                                <h6
                                  className="mt-2 mb-2 poppins-light"
                                  style={{ fontSize: "13.8px" }}
                                >
                                  Have another number? Enter here!
                                </h6>
                                <input
                                  type="number"
                                  className="form-control poppins-light"
                                  autofocus="autofocus"
                                  value={customUnitValue}
                                  placeholder="Enter number of units"
                                  onChange={(e) => {
                                    setCustomUnitValue(e.target.value);
                                    setSelectedOption("");
                                  }}
                                />
                              </div>
                              <div className="d-flex justify-content-center mt-2 w-100">
                                <div className="col-md-3 mt-3 w-100">
                                  <button
                                    className="btn px-5 text-em background-em text-light w-100 poppins-medium book-dealer-submit"
                                    type="submit"
                                  >
                                    Submit
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </>
                      ) : (
                        <>
                          <h1
                            className="mt-5 pt-5 poppins-bold-italic"
                            style={{ fontSize: "70px", color: "#004dac" }}
                          >
                            Oops
                          </h1>
                          <h3 style={{ fontSize: "38px" }} className="mb-5">
                            <em>We are sold out!</em>
                          </h3>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {isUnitsFill && (
            <>
              <div className="d-lg-none" style={{ height: "100vh" }}>
                <div className="details-component text-center p-lg-5 p-4 pb-0">
                  <h1
                    className="mt-3 poppins-bold-italic"
                    style={{ fontSize: "45px", color: "#004dac" }}
                  >
                    Thank You!
                  </h1>
                  <h3 style={{ fontSize: "18px" }} className="">
                    <em>Your deatils have been submitted.</em>
                  </h3>
                  <h5
                    className="my-4 poppins-medium"
                    style={{ color: "#606060", fontSize: "20px" }}
                  >
                    RMs will get in touch with you!
                  </h5>
                  <div className="px-3">
                    <small>
                      In the meantime, why not find out more about us and our
                      ebikes.
                    </small>
                  </div>

                  <div className="d-flex justify-content-center mt-3 w-100">
                    <div className="col-md-3 mt-3 w-100">
                      <Link
                        to={"/"}
                        className="btn px-5 text-em background-em text-light w-100 poppins-medium book-dealer-submit"
                        type="submit"
                      >
                        Explore Our Range
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="w-100">
                  <img
                    className="img-fluid"
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/bookingportal_thanks.png"
                  ></img>
                </div>
              </div>
              <div
                className="d-lg-block d-none"
                style={{
                  backgroundColor: "#F4FCFF",
                  height: "100vh",
                  maxWidth: "100vw",
                  overflow: "hidden",
                }}
              >
                <div className="background-cycle position-relative">
                  <div className="position-absolute form-book-desktop">
                    <div
                      className="details-component bg-white text-center py-lg-5 p-lg-4 p-4 pb-0"
                      style={{ height: "100vh", width: "420px" }}
                    >
                      <h1
                        className="mt-5"
                        style={{ fontFamily: "SpeedBeast", fontSize: "54px" }}
                      >
                        e motorad
                      </h1>
                      <h3 style={{ fontSize: "30px" }}>Booking Portal</h3>
                      <h1
                        className="mt-5 poppins-medium"
                        style={{ fontSize: "55px", color: "#004dac" }}
                      >
                        <em>Thank You!</em>
                      </h1>
                      <h3 style={{ fontSize: "18px" }} className="mt-3">
                        <em>Your deatils have been submitted.</em>
                      </h3>
                      <h5
                        className="my-5 poppins-medium"
                        style={{ color: "#606060", fontSize: "20px" }}
                      >
                        RMs will get in touch with you!
                      </h5>
                      <div className="px-5">
                        <small>
                          In the meantime, why not find out more about us and
                          our ebikes.
                        </small>
                      </div>

                      <div className="d-flex justify-content-center mt-5 w-100">
                        <div className="col-md-3 mt-3 w-100">
                          <Link
                            to={"/"}
                            className="btn px-5 text-em background-em text-light w-100 poppins-medium book-dealer-submit"
                            type="submit"
                          >
                            Explore Our Range
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          {" "}
          <div className="d-lg-none" style={{ height: "100vh" }}>
            <div className="details-component text-center p-lg-5 p-4 pb-0">
              <h1
                className="my-0"
                style={{ fontFamily: "SpeedBeast", fontSize: "30px" }}
              >
                e motorad
              </h1>
              <h3 style={{ fontSize: "18px" }}>Booking Portal</h3>
              <h4 className="my-5 py-5 text-em fw-bold">Coming Soon</h4>
            </div>
            <div className="w-100">
              <img
                className="img-fluid"
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/bookingportal_mobile.png"
              ></img>
            </div>
          </div>
          <div
            className="d-lg-block d-none"
            style={{ backgroundColor: "#F4FCFF", height: "100vh" }}
          >
            <div className="background-cycle position-relative">
              <div className="position-absolute  form-book-desktop">
                <div
                  className="details-component bg-white text-center p-lg-5 p-4 pb-0"
                  style={{ height: "100vh", width: "420px" }}
                >
                  <h1
                    className="my-0"
                    style={{ fontFamily: "SpeedBeast", fontSize: "54px" }}
                  >
                    e motorad
                  </h1>
                  <h3 style={{ fontSize: "30px" }}>Booking Portal</h3>
                  <h4 className="my-5 py-5 text-em fw-bold">Coming Soon</h4>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default BookUnitsScreen;
