import React, { useEffect, useState } from "react";
import { renderToString } from "react-dom/server";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import Meta from "../Components/Meta.js";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import { getAllStores } from "../Actions/storeActions.js";

function FindStoreScreen() {
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [map, setMap] = useState(null);
  const [infoWindow, setInfoWindow] = useState(null);
  const [originMarker, setOriginMarker] = useState(null);
  const [pincode, setPincode] = useState("");
  const [uniqueStates, setUniqueStates] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [filteredCities, setFilteredCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedStores, setSelectedStores] = useState([]);
  const [searchHeaderText, setSearchHeaderText] = useState("");
  const [view, setView] = useState("list");
  const [geoJSONStores, setGeoJSONStores] = useState(null);

  const dispatch = useDispatch();
  const getStores = useSelector((state) => state.getStores);
  const { stores } = getStores;

  useEffect(() => {
    dispatch(getAllStores());
  }, [dispatch]);

  useEffect(() => {
    if (stores && stores.length > 0) {
      const transformStoresToGeoJSON = (stores) => {
        return {
          type: "FeatureCollection",
          features: stores.map((store) => ({
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [
                parseFloat(store.longitude),
                parseFloat(store.latitude),
              ],
            },
            properties: {
              category: "store",
              hours: store.hours,
              address: store.address,
              name: store.name,
              link: store.link,
              phone: store.phoneNumber,
              storeid: store.storeId || "",
              city: store.city,
              state: store.state,
            },
          })),
        };
      };

      const geoJSON = transformStoresToGeoJSON(stores);
      setGeoJSONStores(geoJSON);
      const initialSelectedStores = geoJSON.features.filter((store) =>
        store.properties.name.includes("EMotorad Experience Centre")
      );
      setSelectedStores(initialSelectedStores);
      const states = [
        ...new Set(geoJSON.features.map((item) => item.properties.state)),
      ].sort();
      setUniqueStates(states);
      setSelectedStores(initialSelectedStores);
    }
  }, [stores]);

  const [selectedStoresData, setSelectedStoresData] = useState({
    type: "FeatureCollection",
    features: [],
  });

  useEffect(() => {
    setSelectedStoresData({
      type: "FeatureCollection",
      features: selectedStores,
    });
  }, [selectedStores]);

  useEffect(() => {
    if (geoJSONStores && geoJSONStores.features) {
      const cities = [
        ...new Set(
          geoJSONStores.features
            .filter((item) => item.properties.state === selectedState)
            .map((item) => item.properties.city)
        ),
      ].sort();
      setFilteredCities(cities);
    }
  }, [selectedState, geoJSONStores]);

  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
    setSelectedCity("");
    setPincode("");
  };

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
    setPincode("");
  };

  const handlePincodeChange = (e) => {
    setPincode(e.target.value);
    setSelectedState("");
    setSelectedCity("");
  };

  const handleSearch = () => {
    let selectedStoresData;
    if (!(pincode === "")) {
      selectedStoresData = geoJSONStores.features;
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: pincode }, (results, status) => {
        if (status === "OK" && results.length > 0) {
          const location = results[0].geometry.location;

          map.setCenter(location);
          map.setZoom(12);
          const marker = new window.google.maps.Marker({
            position: location,
            map: map,
            title: "Pincode Location",
          });
        } else {
          console.error(
            "Geocode was not successful for the following reason:",
            status
          );
        }
      });
    } else if (!(selectedCity === "" && selectedState === "")) {
      selectedStoresData = geoJSONStores.features.filter(
        (item) =>
          item.properties.state === selectedState &&
          item.properties.city === selectedCity
      );
    } else {
      selectedStoresData = selectedStores;
    }
    setSelectedStores(selectedStoresData);
    setSearchHeaderText(`${selectedCity}, ${selectedState}`);
  };

  const initializeMap = () => {
    const newMap = new window.google.maps.Map(document.getElementById("map"), {
      zoom: 5,
      center: { lat: 20.5937, lng: 78.9629 },
    });
    setMap(newMap);

    newMap.data.setStyle((feature) => {
      return {
        icon: {
          url: `https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/store.png`,
          scaledSize: new window.google.maps.Size(70, 70),
        },
      };
    });

    const newInfoWindow = new window.google.maps.InfoWindow();
    setInfoWindow(newInfoWindow);
    newMap.data.addListener("click", (event) => {
      const category = event.feature.getProperty("category");
      const name = event.feature.getProperty("name");
      const address = event.feature.getProperty("address");
      const phone = event.feature.getProperty("phone");
      const link = event.feature.getProperty("link");
      const position = event.feature.getGeometry().get();
      const content = (
        <div className="d-flex p-lg-3 align-items-center">
          <img
            className="w-25 d-none d-lg-block"
            src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/Green.png"
            alt="store-icon"
          />
          <div className="ps-lg-3">
            <h4 className="d-none d-lg-block">{name}</h4>
            <h6 className="d-lg-none">{name}</h6>
            <p></p>
            <p className="d-flex align-items-center">
              <b>
                <i className="fa-solid  fa-location-dot me-3"></i>
              </b>
              {address}
            </p>
            <p>
              <b>
                <i className="fa-solid fa-phone me-3"></i>
              </b>{" "}
              <a href={`tel:${phone}`}>{phone}</a>
            </p>
            <p>
              <b>
                {" "}
                <i className="fa-solid fa-map-location-dot me-3"></i>
              </b>{" "}
              <a href={link} target="_blank" rel="noopener noreferrer">
                Find Direction
              </a>
            </p>
          </div>
        </div>
      );

      newInfoWindow.setContent(renderToString(content));
      newInfoWindow.setPosition(position);
      newInfoWindow.setOptions({
        pixelOffset: new window.google.maps.Size(0, -30),
      });
      newInfoWindow.open(newMap);
    });

    const newOriginMarker = new window.google.maps.Marker({ map: newMap });
    newOriginMarker.setVisible(false);
    setOriginMarker(newOriginMarker);
  };
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyBIaH1D753G5bOfcm7kExBfP7uwOQoqHYo&libraries=places&callback=initMap&solution_channel=GMP_codelabs_simplestorelocator_v1_a";
    script.async = true;
    script.onload = () => {
      initializeMap();
    };
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (map && selectedStoresData.features.length > 0) {
      map.data.forEach((feature) => {
        map.data.remove(feature);
      });

      map.data.addGeoJson(selectedStoresData, { idPropertyName: "storeid" });
      if (!(selectedCity === "" && selectedState === "")) {
        const city = selectedCity;
        const state = selectedState;
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode(
          { address: `${city}, ${state}` },
          (results, status) => {
            if (status === "OK" && results.length > 0) {
              map.setCenter(results[0].geometry.location);
              map.setZoom(12);
            } else {
              console.error(
                "Geocode was not successful for the following reason:",
                status
              );
            }
          }
        );
      }
    }
  }, [map, selectedStoresData]);

  const toggleView = (selectedView) => {
    setView(selectedView);
  };

  useEffect(() => {
    const mapElement = document.getElementById("map");

    if (view === "map") {
      mapElement.style.display = "block";
    } else {
      setPincode("");
      if (!(pincode === "")) {
        setSelectedStores(selectedStores);
      }
      if (pincode === "" && selectedCity === "" && selectedState === "") {
        setSelectedStores(selectedStores);
      }
      mapElement.style.display = "none";
    }
  }, [view]);

  const sortedStores = [...selectedStores];
  sortedStores.sort((a, b) => {
    const nameA = a.properties.name.toUpperCase();
    const nameB = b.properties.name.toUpperCase();

    if (
      nameA === "EMOTORAD EXPERIENCE CENTRE" &&
      nameB !== "EMOTORAD EXPERIENCE CENTRE"
    ) {
      return -1;
    } else if (
      nameB === "EMOTORAD EXPERIENCE CENTRE" &&
      nameA !== "EMOTORAD EXPERIENCE CENTRE"
    ) {
      return 1;
    } else {
      return nameA.localeCompare(nameB);
    }
  });

  return (
    <>
      <Meta
        title={"Electric Cycle Showroom Near Me | EMotorad Electric Bike Shops"}
        description={
          "Looking for an electric cycle showroom near you? Visit EMotorad's electric bike showrooms for a hands-on experience with our top-of-the-line e-bikes."
        }
        cannonicalTag={"https://www.emotorad.com/find-e-bicycle-store"}
      />
      <Navbar />
      <picture className="main-banner-img">
        <source
          media="(max-width:650px)"
          srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/testRide+Page/Mobile_Ecycle+Better+hai-100.jpg"
        />
        <source
          media="(min-width:651px)"
          srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/testRide+Page/Website+Banner_Main+1920x650.jpg"
        />
        <img
          src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/testRide+Page/Website+Banner_Main+1920x650.jpg"
          alt="Banner1"
          className="d-block w-100"
        />
      </picture>
      <div id="store-container">
        <div className="find-store-heading-container">
          <h1
            className="store-heading"
            style={{ fontFamily: "Metropolis-bolder" }}
          >
            <b>Find E Cycle Stores Near You</b>
          </h1>
          <div
            id="toggle-buttons"
            className="btn-group"
            style={{ border: "solid 2px black", borderRadius: "8px" }}
          >
            <button
              id="list-button"
              type="button"
              className={`btn ${view === "list" ? "active" : ""}`}
              onClick={() => toggleView("list")}
            >
              <i className="fas fa-list"></i>
              <b> List</b>
            </button>
            <button
              id="map-button"
              type="button"
              className={`btn ${view === "map" ? "active" : ""}`}
              onClick={() => toggleView("map")}
            >
              <i className="fas fa-map"></i>
              <b> Maps</b>
            </button>
          </div>
        </div>
        <div id="pac-card-new">
          <div id="pac-container-new">
            <div className="row mt-2">
              {view === "map" && (
                <div className="col-md-2">
                  <h5 className=" mt-2 mb-2">Pin Code</h5>
                  <input
                    className="form-control"
                    type="text"
                    id="pincode"
                    name="pincode"
                    value={pincode}
                    onChange={handlePincodeChange}
                  />
                </div>
              )}
              <div className="col-md-4">
                <h5 className=" mt-2 mb-2">States</h5>
                <select
                  id="state-dropdown"
                  className="form-select"
                  aria-label={pincode ? "Select a State" : ""}
                  value={selectedState}
                  onChange={handleStateChange}
                >
                  <option value="" disabled>
                    Select a State
                  </option>
                  {uniqueStates.map((state, index) => (
                    <option key={index} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-4">
                <h5 className="mt-2 mb-2">Cities</h5>
                <select
                  id="city-dropdown"
                  className="form-select"
                  aria-label={pincode ? "Select a City" : ""}
                  value={selectedCity}
                  onChange={handleCityChange}
                >
                  <option value="" disabled>
                    Select a City
                  </option>
                  {filteredCities.map((city, index) => (
                    <option key={index} value={city}>
                      {city}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mt-4 col-md-2 d-flex align-items-end justify-content-center">
                <button
                  className="all-bikes-main-banner-btn btn rounded-3 text-center text-decoration-none p-4 pt-2 pb-2 p"
                  onClick={handleSearch}
                  style={{ fontSize: "1.1em" }}
                >
                  Search
                </button>
              </div>

              {view === "list" && (
                <div className="mt-3 col-md-12">
                  {selectedStores.length > 0 ? (
                    <div>
                      {selectedStores.length > 0 &&
                      selectedStores.every((store) =>
                        store.properties.name.includes(
                          "EMotorad Experience Centre"
                        )
                      ) ? (
                        <>
                          <h5 className="mt-3 mb-5">
                            <b>EMotorad Experience Centres</b>
                          </h5>
                        </>
                      ) : (
                        <h5 className="mt-3 mb-5">
                          Stores in <b>{searchHeaderText}</b>
                        </h5>
                      )}

                      {sortedStores.map((store, index) => (
                        <div id="store-div-list" key={index}>
                          <h4>
                            <b>{store.properties.name} </b>
                          </h4>
                          <p>{store.properties.address}</p>
                          <div
                            className="d-flex align-items-center"
                            style={{ gap: "2em" }}
                          >
                            <a
                              href={store.properties.link}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="directions-link"
                            >
                              <i className="fa-solid fa-diamond-turn-right"></i>{" "}
                              <b>Directions</b>
                            </a>
                            <a
                              href="tel:+918686050590"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="directions-link"
                            >
                              <Icon
                                icon="material-symbols:call"
                                width="1.3em"
                                height="1.3em"
                                style={{ color: "#004dac" }}
                              />{" "}
                              <b>Call Now</b>
                            </a>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : null}
                  <h4 className="text-center fw-bold pt-4">
                    Can't find a store? Don't worry, we got you! Call us here at{" "}
                    <a
                      href="href='tel:+918686050590"
                      className="text-decoration-none"
                    >
                      +918686050590
                    </a>
                  </h4>
                </div>
              )}
            </div>
          </div>
        </div>
        <div></div>
        <div id="map"></div>
      </div>
      <Footer />
    </>
  );
}

export default FindStoreScreen;
