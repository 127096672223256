import {
  GET_ALLBIKES_REQUEST,
  GET_ALLBIKES_FAIL,
  GET_ALLBIKES_SUCCESS,
  GET_BIKE_BY_NAME_FAIL,
  GET_BIKE_BY_NAME_REQUEST,
  GET_BIKE_BY_NAME_SUCCESS,
  GET_PRODUCT_BY_SUB_CATEGORY_REQUEST,
  GET_PRODUCT_BY_SUB_CATEGORY_SUCCESS,
  GET_PRODUCT_BY_SUB_CATEGORY_FAIL,
  GET_ALL_ACCESSORIES_REQUEST,
  GET_ALL_ACCESSORIES_SUCCESS,
  GET_ALL_ACCESSORIES_FAIL,
  GET_ALL_XCAPE_ACCESSORIES_REQUEST,
  GET_ALL_XCAPE_ACCESSORIES_SUCCESS,
  GET_ALL_XCAPE_ACCESSORIES_FAIL,
} from "../Constants/bikeConstants.js";

export const allBikesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALLBIKES_REQUEST:
      return { loading: true };
    case GET_ALLBIKES_SUCCESS:
      return { loading: false, bikes: action.payload };
    case GET_ALLBIKES_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const bikeByNameReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_BIKE_BY_NAME_REQUEST:
      return { loading: true };
    case GET_BIKE_BY_NAME_SUCCESS:
      return { loading: false, bike: action.payload };
    case GET_BIKE_BY_NAME_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const productBySubCategoryReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PRODUCT_BY_SUB_CATEGORY_REQUEST:
      return { loading: true };
    case GET_PRODUCT_BY_SUB_CATEGORY_SUCCESS:
      return { loading: false, products: action.payload };
    case GET_PRODUCT_BY_SUB_CATEGORY_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const allAccessoriesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_ACCESSORIES_REQUEST:
      return { loading: true };
    case GET_ALL_ACCESSORIES_SUCCESS:
      return { loading: false, accessories: action.payload };
    case GET_ALL_ACCESSORIES_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const allXcapeAccessoriesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_XCAPE_ACCESSORIES_REQUEST:
      return { loading: true };
    case GET_ALL_XCAPE_ACCESSORIES_SUCCESS:
      return { loading: false, accessories: action.payload };
    case GET_ALL_XCAPE_ACCESSORIES_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
