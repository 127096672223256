import React from "react";
import { Link } from "react-router-dom";

const PreBookDoneScreen = () => {
  return (
    <>
      <div className="d-lg-none" style={{ height: "100vh" }}>
        <div className="details-component text-center p-lg-5 p-4 pb-0">
          <h1
            className="mt-3 poppins-bold-italic"
            style={{ fontSize: "35px", color: "#004dac" }}
          >
            Back Again?
          </h1>
          <h3 style={{ fontSize: "16px" }} className="">
            <em>We already recieved your order and we are processing it..</em>
          </h3>
          <h5
            className="my-4 poppins-medium"
            style={{ color: "#606060", fontSize: "19px" }}
          >
            For futher details get in touch with our RM's.
          </h5>
          <div className="px-3">
            <small>Want to chekout more?</small>
          </div>

          <div className="d-flex justify-content-center mt-3 w-100">
            <div className="col-md-3 mt-3 w-100">
              <Link
                to={"/"}
                className="btn px-5 text-em background-em text-light w-100 poppins-medium book-dealer-submit"
                type="submit"
              >
                Explore Our Range
              </Link>
            </div>
          </div>
        </div>
        <div className="w-100">
          <img
            className="img-fluid"
            src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/bookingportal_thanks.png"
          ></img>
        </div>
      </div>
      <div
        className="d-lg-block d-none"
        style={{
          backgroundColor: "#F4FCFF",
          height: "100vh",
          maxWidth: "100vw",
          overflow: "hidden",
        }}
      >
        <div className="background-cycle position-relative">
          <div className="position-absolute form-book-desktop">
            <div
              className="details-component bg-white text-center py-lg-5 p-lg-4 p-4 pb-0"
              style={{ height: "100vh", width: "420px" }}
            >
              <h1
                className="mt-5"
                style={{ fontFamily: "SpeedBeast", fontSize: "54px" }}
              >
                e motorad
              </h1>
              <h3 style={{ fontSize: "30px" }}>Booking Portal</h3>
              <h1
                className="mt-5 poppins-medium"
                style={{ fontSize: "55px", color: "#004dac" }}
              >
                <em>Back Again?</em>
              </h1>
              <h3 style={{ fontSize: "18px" }} className="mt-3">
                <em>
                  We already recieved your order and we are processing it..
                </em>
              </h3>
              <h5
                className="my-5 poppins-medium"
                style={{ color: "#606060", fontSize: "20px" }}
              >
                For futher details get in touch with our RM's.
              </h5>
              <div className="px-5">
                <small>Want to chekout more?</small>
              </div>

              <div className="d-flex justify-content-center mt-5 w-100">
                <div className="col-md-3 mt-3 w-100">
                  <Link
                    to={"/"}
                    className="btn px-5 text-em background-em text-light w-100 poppins-medium book-dealer-submit"
                    type="submit"
                  >
                    Explore Our Range
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreBookDoneScreen;
